export const taxation_data = [
    {
        id: 1,
        name: "Income Tax"
    },
    {
        id: 2,
        name: "Corporate Tax"
    },
    {
        id: 3,
        name: "Property Tax"
    },
    {
        id: 4,
        name: "Goods and Services Tax"
    },
    {
        id: 5,
        name: "Value Added Tax"
    },
    {
        id: 6,
        name: "Excise Tax"
    }
]


