export const currencyData = [
  {
    currency: "AED",
    symbol: "د.إ",
    name: "UAE Dirham",
    country: "UAE",
  },
  {
    currency: "AFN",
    symbol: "Af",
    name: "Afghani",
    country: "Afghanistan",
  },
  {
    currency: "ALL",
    symbol: "L",
    name: "Lek",
    country: "Albania",
  },
  {
    currency: "AMD",
    symbol: "Դ",
    name: "Armenian Dram",
    country: "Armenia",
  },
  {
    currency: "AOA",
    symbol: "Kz",
    name: "Kwanza",
    country: "Angola",
  },
  {
    currency: "ARS",
    symbol: "$",
    name: "	Australian Dollar",
    country: "Australia",
  },
  {
    currency: "ARS",
    symbol: "$",
    name: "	Australian Dollar",
    country: "Kiribati",
  },
  {
    currency: "ARS",
    symbol: "$",
    name: "	Australian Dollar",
    country: "Coconut Islands",
  },
  {
    currency: "ARS",
    symbol: "$",
    name: "	Australian Dollar",
    country: "Nauru",
  },
  {
    currency: "ARS",
    symbol: "$",
    name: "	Australian Dollar",
    country: "Tuvalu",
  },
  {
    currency: "AWG",
    symbol: "ƒ",
    name: "	Aruban Guilder/Florin",
    country: "Aruba",
  },
  {
    currency: "AZN",
    symbol: "ман",
    name: "	Azerbaijanian Manat",
    country: "Azerbaijan",
  },
  {
    currency: "BAM",
    symbol: "КМ",
    name: "	Konvertibilna Marka",
    country: "Bosnia and Herzegovina",
  },
  {
    currency: "BBD",
    symbol: "$",
    name: "	Barbados Dollar",
    country: "Barbados",
  },
  {
    currency: "BDT",
    symbol: "৳",
    name: "	Taka",
    country: "Bangladesh",
  },
  {
    currency: "BGN",
    symbol: "лв",
    name: "	Bulgarian Lev",
    country: "Bulgaria",
  },
  {
    currency: "BHD",
    symbol: "ب.د",
    name: "	Bahraini Dinar",
    country: "Bahrain",
  },
  {
    currency: "BIF",
    symbol: "₣",
    name: "	Burundi Franc",
    country: "Burundi",
  },
  {
    currency: "BMD",
    symbol: "$",
    name: "	Bermudian Dollar",
    country: "Bermuda",
  },
  {
    currency: "BND",
    symbol: "$",
    name: "	Brunei Dollar",
    country: "Brunei",
  },
  {
    currency: "BND",
    symbol: "$",
    name: "	Brunei Dollar",
    country: "Singapore",
  },
  {
    currency: "BOB",
    symbol: "Bs.",
    name: "	Boliviano",
    country: "Bolivia",
  },
  {
    currency: "BRL",
    symbol: "R$",
    name: "	Brazilian Real",
    country: "Brazil",
  },
  {
    currency: "BSD",
    symbol: "$",
    name: "	Bahamian Dollar",
    country: "Bahamas",
  },
  {
    currency: "BTN",
    symbol: "",
    name: "	Ngultrum",
    country: "Bhutan",
  },
  {
    currency: "BWP",
    symbol: "P",
    name: "Pula",
    country: "Botswana",
  },
  {
    currency: "BYN",
    symbol: "Br",
    name: "Belarusian Ruble",
    country: "Belarus",
  },
  {
    currency: "BZD",
    symbol: "$",
    name: "Belize Dollar",
    country: "Belize",
  },
  {
    currency: "CAD",
    symbol: "$",
    name: "Canadian Dollar",
    country: "Canada",
  },
  {
    currency: "CDF",
    symbol: "₣",
    name: "Congolese Franc",
    country: "Congo (Kinshasa)",
  },
  {
    currency: "CHF",
    symbol: "₣",
    name: "Swiss Franc",
    country: "Lichtenstein",
  },
  {
    currency: "CHF",
    symbol: "₣",
    name: "Swiss Franc",
    country: "Switzerland",
  },
  {
    currency: "CLP",
    symbol: "$",
    name: "Chilean Peso",
    country: "Chile",
  },
  {
    currency: "CNY",
    symbol: "¥",
    name: "Yuan",
    country: "China",
  },
  {
    currency: "COP",
    symbol: "$",
    name: "Colombian Peso",
    country: "Colombia",
  },
  {
    currency: "CRC",
    symbol: "₡",
    name: "Costa Rican Colon",
    country: "Costa Rica",
  },
  {
    currency: "BWP",
    symbol: "P",
    name: "Pula",
    country: "Botswana",
  },
  {
    currency: "BYN",
    symbol: "Br",
    name: "Belarusian Ruble",
    country: "Belarus",
  },
  {
    currency: "BZD",
    symbol: "$",
    name: "Belize Dollar",
    country: "Belize",
  },
  {
    currency: "CAD",
    symbol: "$",
    name: "Canadian Dollar",
    country: "Canada",
  },
  {
    currency: "CDF",
    symbol: "₣",
    name: "Congolese Franc",
    country: "Congo (Kinshasa)",
  },
  {
    currency: "CHF",
    symbol: "₣",
    name: "Swiss Franc",
    country: "Lichtenstein",
  },
  {
    currency: "CHF",
    symbol: "₣",
    name: "Swiss Franc",
    country: "Switzerland",
  },
  {
    currency: "CLP",
    symbol: "¥",
    name: "Yuan",
    country: "China",
  },
  {
    currency: "COP",
    symbol: "$",
    name: "Colombian Peso",
    country: "Colombia",
  },
  {
    currency: "CRC",
    symbol: "₡",
    name: "Costa Rican Colon",
    country: "Costa Rica",
  },
  {
    currency: "CUP",
    symbol: "$",
    name: "Cuban Peso",
    country: "Cuba",
  },
  {
    currency: "CVE",
    symbol: "$",
    name: "Cape Verde Escudo",
    country: "Cape Verde",
  },
  {
    currency: "CZK",
    symbol: "Kč",
    name: "Czech Koruna",
    country: "Czech Republic",
  },
  {
    currency: "DJF",
    symbol: "₣",
    name: "Djibouti Franc",
    country: "Djibouti",
  },
  {
    currency: "DKK",
    symbol: "kr",
    name: "Danish Krone",
    country: "Denmark",
  },
  {
    currency: "DOP",
    symbol: "$",
    name: "Dominican Peso",
    country: "Dominican Republic",
  },
  {
    currency: "DZD",
    symbol: "د.ج",
    name: "Algerian Dinar",
    country: "Algeria",
  },
  {
    currency: "EGP",
    symbol: "£",
    name: "Egyptian Pound",
    country: "Egypt",
  },
  {
    currency: "ERN",
    symbol: "Nfk",
    name: "Nakfa",
    country: "Eritrea",
  },
  {
    currency: "ETB",
    symbol: "",
    name: "Ethiopian Birr",
    country: "Ethiopia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Akrotiri and Dhekelia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Andorra",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Austria",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Belgium",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Cyprus",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Estonia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Finland",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "France",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Germany",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Greece",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Ireland",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Italy",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Kosovo",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Latvia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Lithuania",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Luxembourg",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Malta",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Monaco",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Montenegro",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Netherlands",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Portugal",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "San-Marino",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Slovakia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Slovenia",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Spain",
  },
  {
    currency: "EUR",
    symbol: "€",
    name: "Euro",
    country: "Vatican",
  },
  {
    currency: "FJD",
    symbol: "$",
    name: "Fiji Dollar",
    country: "Fiji",
  },
  {
    currency: "FKP",
    symbol: "£",
    name: "Falkland Islands Pound",
    country: "Falkland Islands",
  },
  {
    currency: "GBP",
    symbol: "£",
    name: "Pound Sterling",
    country: "Alderney",
  },
  {
    currency: "GBP",
    symbol: "£",
    name: "Pound Sterling",
    country: "British Indian Ocean Territory",
  },
  {
    currency: "GBP",
    symbol: "£",
    name: "Pound Sterling",
    country: "Great Britain",
  },
  {
    currency: "GBP",
    symbol: "£",
    name: "Pound Sterling",
    country: "Isle of Maine",
  },
  {
    currency: "GEL",
    symbol: "ლ",
    name: "Lari",
    country: "Georgia",
  },
  {
    currency: "GEL",
    symbol: "ლ",
    name: "Lari",
    country: "South Ossetia",
  },
  {
    currency: "GHS",
    symbol: "₵",
    name: "Cedi",
    country: "Ghana",
  },
  {
    currency: "GIP",
    symbol: "£",
    name: "Gibraltar Pound",
    country: "Gibraltar",
  },
  {
    currency: "GMD",
    symbol: "D",
    name: "Dalasi",
    country: "Gambia",
  },
  {
    currency: "GNF",
    symbol: "₣",
    name: "Guinea Franc",
    country: "Guinea",
  },
  {
    currency: "GTQ",
    symbol: "Q",
    name: "Quetzal",
    country: "Guatemala",
  },
  {
    currency: "GYD",
    symbol: "$",
    name: "Guyana Dollar",
    country: "Guyana",
  },
  {
    currency: "HKD",
    symbol: "$",
    name: "Hong Kong Dollar",
    country: "Hong Kong",
  },
  {
    currency: "HNL",
    symbol: "L",
    name: "Lempira",
    country: "Honduras",
  },
  {
    currency: "HRK",
    symbol: "Kn",
    name: "Croatian Kuna",
    country: "Croatia",
  },
  {
    currency: "HTG",
    symbol: "G",
    name: "Gourde",
    country: "Haiti",
  },
  {
    currency: "HUF",
    symbol: "Ft",
    name: "Forint",
    country: "Hungary",
  },
  {
    currency: "IDR",
    symbol: "Rp",
    name: "Rupiah",
    country: "Indonesia",
  },
  {
    currency: "ILS",
    symbol: "₪",
    name: "New Israeli Shekel",
    country: "Israel",
  },
  {
    currency: "ILS",
    symbol: "₪",
    name: "New Israeli Shekel",
    country: "Palestine",
  },
  {
    currency: "INR",
    symbol: "₹",
    name: "Indian Rupee",
    country: "Bhutan",
  },
  {
    currency: "INR",
    symbol: "₹",
    name: "Indian Rupee",
    country: "India",
  },
  {
    currency: "IQD",
    symbol: "	ع.د",
    name: "Iraqi Dinar",
    country: "Iraq",
  },
  {
    currency: "IRR",
    symbol: "﷼",
    name: "Iranian Rial",
    country: "Iran",
  },
  {
    currency: "ISK",
    symbol: "Kr",
    name: "Iceland Krona",
    country: "Iceland",
  },
  {
    currency: "JMD",
    symbol: "$",
    name: "Jamaican Dollar",
    country: "Jamaica",
  },
  {
    currency: "JOD",
    symbol: "د.ا",
    name: "Jordanian Dinar",
    country: "Jordan",
  },
  {
    currency: "JPY",
    symbol: "¥",
    name: "Yen",
    country: "Japan",
  },
  {
    currency: "KES",
    symbol: "Sh",
    name: "Kenyan Shilling",
    country: "Kenya",
  },
  {
    currency: "KGS",
    symbol: "",
    name: "Som",
    country: "Kyrgyzstan",
  },
  {
    currency: "KHR",
    symbol: "៛",
    name: "Riel",
    country: "Cambodia",
  },
  {
    currency: "KPW",
    symbol: "₩",
    name: "North Korean Won",
    country: "North Korea",
  },
  {
    currency: "KRW",
    symbol: "₩",
    name: "South Korean Won",
    country: "South Korea",
  },
  {
    currency: "KWD",
    symbol: "د.ك",
    name: "Kuwaiti Dinar",
    country: "Kuwait",
  },
  {
    currency: "KYD",
    symbol: "$",
    name: "Cayman Islands Dollar",
    country: "Cayman Islands",
  },
  {
    currency: "KZT",
    symbol: "〒",
    name: "Tenge",
    country: "Kazakhstan",
  },
  {
    currency: "LAK",
    symbol: "₭",
    name: "Kip",
    country: "Laos",
  },
  {
    currency: "LBP",
    symbol: "ل.ل",
    name: "Lebanese Pound",
    country: "Lebanon",
  },
  {
    currency: "LKR",
    symbol: "Rs",
    name: "Sri Lanka Rupee",
    country: "Sri Lanka",
  },
  {
    currency: "LRD",
    symbol: "$",
    name: "Liberian Dollar",
    country: "Liberia",
  },
  {
    currency: "LSL",
    symbol: "L",
    name: "Loti",
    country: "Lesotho",
  },
  {
    currency: "LYD",
    symbol: "ل.د",
    name: "Libyan Dinar",
    country: "Libya",
  },
  {
    currency: "MAD",
    symbol: "د.م.",
    name: "Moroccan Dirham",
    country: "Morocco",
  },
  {
    currency: "MDL",
    symbol: "L",
    name: "Moldovan Leu",
    country: "Moldova",
  },
  {
    currency: "MGA",
    symbol: "",
    name: "Malagasy Ariary",
    country: "Madagascar",
  },
  {
    currency: "MKD",
    symbol: "ден",
    name: "Denar",
    country: "Macedonia",
  },
  {
    currency: "MMK",
    symbol: "K",
    name: "Kyat",
    country: "Myanmar (Burma)",
  },
  {
    currency: "MNT",
    symbol: "₮",
    name: "Tugrik",
    country: "Mongolia",
  },
  {
    currency: "MOP",
    symbol: "P",
    name: "Pataca",
    country: "Macao",
  },
  {
    currency: "MRU",
    symbol: "UM",
    name: "Ouguiya",
    country: "Mauritania",
  },
  {
    currency: "MUR",
    symbol: "₨",
    name: "Mauritius Rupee",
    country: "Mauritius",
  },
  {
    currency: "MVR",
    symbol: "ރ.",
    name: "Rufiyaa",
    country: "Maldives",
  },
  {
    currency: "MWK",
    symbol: "MK",
    name: "Kwacha",
    country: "Malawi",
  },
  {
    currency: "MXN",
    symbol: "$",
    name: "Mexican Peso",
    country: "Mexico",
  },
  {
    currency: "MYR",
    symbol: "RM",
    name: "Malaysian Ringgit",
    country: "Malaysia",
  },
  {
    currency: "MZN",
    symbol: "MTn",
    name: "Metical",
    country: "Mozambique",
  },
  {
    currency: "NAD",
    symbol: "$",
    name: "Namibia Dollar",
    country: "Namibia",
  },
  {
    currency: "NGN",
    symbol: "₦",
    name: "Naira",
    country: "Nigeria",
  },
  {
    currency: "NIO",
    symbol: "C$",
    name: "Cordoba Oro",
    country: "Nicaragua",
  },
  {
    currency: "NOK",
    symbol: "kr",
    name: "Norwegian Krone",
    country: "Norway",
  },
  {
    currency: "NPR",
    symbol: "₨",
    name: "Nepalese Rupee",
    country: "Nepal",
  },
  {
    currency: "NZD",
    symbol: "$",
    name: "New Zealand Dollar",
    country: "Cook Islands",
  },
  {
    currency: "NZD",
    symbol: "$",
    name: "New Zealand Dollar",
    country: "New Zealand",
  },
  {
    currency: "NZD",
    symbol: "$",
    name: "New Zealand Dollar",
    country: "Niue",
  },
  {
    currency: "NZD",
    symbol: "$",
    name: "New Zealand Dollar",
    country: "Pitcairn Island",
  },
  {
    currency: "OMR",
    symbol: "ر.ع.",
    name: "Rial Omani",
    country: "Oman",
  },
  {
    currency: "PAB",
    symbol: "B/.",
    name: "Balboa",
    country: "Panama",
  },
  {
    currency: "PEN",
    symbol: "S/.",
    name: "Nuevo Sol",
    country: "Peru",
  },
  {
    currency: "PGK",
    symbol: "K",
    name: "Kina",
    country: "Papua New Guinea",
  },
  {
    currency: "PHP",
    symbol: "₱",
    name: "Philippine Peso",
    country: "Philippines",
  },
  {
    currency: "PKR",
    symbol: "₨",
    name: "Pakistan Rupee",
    country: "Pakistan",
  },
  {
    currency: "PLN",
    symbol: "zł",
    name: "PZloty",
    country: "Poland",
  },
  {
    currency: "PYG",
    symbol: "₲",
    name: "Guarani",
    country: "Paraguay",
  },
  {
    currency: "QAR",
    symbol: "ر.ق",
    name: "Qatari Rial",
    country: "Qatar",
  },
  {
    currency: "RON",
    symbol: "L",
    name: "Leu",
    country: "Romania",
  },
  {
    currency: "RSD",
    symbol: "din",
    name: "Serbian Dinar",
    country: "Kosovo",
  },
  {
    currency: "RSD",
    symbol: "din",
    name: "Serbian Dinar",
    country: "Serbia",
  },
  {
    currency: "RUB",
    symbol: "	р.",
    name: "Russian Ruble",
    country: "Russia",
  },
  {
    currency: "RUB",
    symbol: "	р.",
    name: "Russian Ruble",
    country: "South Ossetia",
  },
  {
    currency: "RWF",
    symbol: "₣",
    name: "Rwanda Franc",
    country: "Rwanda",
  },
  {
    currency: "SAR",
    symbol: "ر.س",
    name: "Saudi Riyal",
    country: "Saudi Arabia",
  },
  {
    currency: "SBD",
    symbol: "$",
    name: "Solomon Islands Dollar",
    country: "Solomon Islands",
  },
  {
    currency: "SCR",
    symbol: "₨",
    name: "Seychelles Rupee",
    country: "Seychelles",
  },
  {
    currency: "SDG",
    symbol: "£",
    name: "Sudanese Pound",
    country: "Sudan",
  },
  {
    currency: "SEK",
    symbol: "kr",
    name: "Swedish Krona",
    country: "Sweden",
  },
  {
    currency: "SGD",
    symbol: "$",
    name: "Singapore Dollar",
    country: "Brunei",
  },
  {
    currency: "SGD",
    symbol: "$",
    name: "Singapore Dollar",
    country: "Singapore",
  },
  {
    currency: "SHP",
    symbol: "£",
    name: "Saint Helena Pound",
    country: "Ascension Island",
  },
  {
    currency: "SHP",
    symbol: "£",
    name: "Saint Helena Pound",
    country: "Saint Helena",
  },
  {
    currency: "SHP",
    symbol: "£",
    name: "Saint Helena Pound",
    country: "Tristan da Cunha",
  },
  {
    currency: "SLL",
    symbol: "Le",
    name: "Leone",
    country: "Sierra Leone",
  },
  {
    currency: "SOS",
    symbol: "Sh",
    name: "Somali Shilling",
    country: "Somalia",
  },
  {
    currency: "SRD",
    symbol: "$",
    name: "Suriname Dollar",
    country: "Suriname",
  },
  {
    currency: "STN",
    symbol: "Db",
    name: "Dobra",
    country: "Sao Tome and Principe",
  },
  {
    currency: "SYP",
    symbol: "ل.س",
    name: "Syrian Pound",
    country: "Syria",
  },
  {
    currency: "SZL",
    symbol: "L",
    name: "Lilangeni",
    country: "Swaziland",
  },
  {
    currency: "THB",
    symbol: "฿",
    name: "Baht",
    country: "Thailand",
  },
  {
    currency: "TJS",
    symbol: "ЅМ",
    name: "Somoni",
    country: "Tajikistan",
  },
  {
    currency: "TMT",
    symbol: "m",
    name: "Manat",
    country: "Turkmenistan",
  },
  {
    currency: "TND",
    symbol: "د.ت",
    name: "Tunisian Dinar",
    country: "Tunisia",
  },
  {
    currency: "TOP",
    symbol: "T$",
    name: "Pa’anga",
    country: "Tonga",
  },
  {
    currency: "TRY",
    symbol: "	₤",
    name: "Turkish Lira",
    country: "North Cyprus",
  },
  {
    currency: "TRY",
    symbol: "	₤",
    name: "Turkish Lira",
    country: "Turkey",
  },
  {
    currency: "TTD",
    symbol: "Sh",
    name: "Trinidad and Tobago Dollar",
    country: "Trinidad and Tobago",
  },
  {
    currency: "TWD",
    symbol: "$",
    name: "Taiwan Dollar",
    country: "Taiwan",
  },
  {
    currency: "TZS",
    symbol: "Sh",
    name: "Tanzanian Shilling",
    country: "Tanzania",
  },
  {
    currency: "UAH",
    symbol: "₴",
    name: "Hryvnia",
    country: "Ukraine",
  },
  {
    currency: "UGX",
    symbol: "Sh",
    name: "Uganda Shilling",
    country: "Uganda",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "American Samoa",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "British Indian Ocean Territory",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "British Virgin Islands",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Guam",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Haiti",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Marshall Islands",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Micronesia",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Northern Mariana Islands",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Pacific Remote islands",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Palau",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Panama",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Puerto Rico",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "Turks and Caicos Islands",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "United States of America",
  },
  {
    currency: "USD",
    symbol: "$",
    name: "US Dollar",
    country: "US Virgin Islands",
  },
  {
    currency: "UYU",
    symbol: "$",
    name: "Peso Uruguayo",
    country: "Uruguay",
  },
  {
    currency: "UZS",
    symbol: "",
    name: "Uzbekistan Sum",
    country: "Uzbekistan",
  },
  {
    currency: "VEF",
    symbol: "Bs F",
    name: "Bolivar Fuerte",
    country: "Venezuela",
  },
  {
    currency: "VND",
    symbol: "₫",
    name: "Dong",
    country: "Vietnam",
  },
  {
    currency: "VUV",
    symbol: "Vt",
    name: "Vatu",
    country: "Vanuatu",
  },
  {
    currency: "WST",
    symbol: "T",
    name: "Tala",
    country: "Samoa",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Benin",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Burkina Faso",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Cameroon",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Central African Republic",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Chad",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Congo (Brazzaville)",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Côte d'Ivoire",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Equatorial Guinea",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Gabon",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Guinea-Bissau",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Mali",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Niger",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Senegal",
  },
  {
    currency: "XAF",
    symbol: "₣",
    name: "CFA Franc BCEAO",
    country: "Togo",
  },

  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Anguilla",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Antigua and Barbuda",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Dominica",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Grenada",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Montserrat",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Saint Kitts and Nevis",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Saint Lucia",
  },
  {
    currency: "XCD",
    symbol: "$",
    name: "East Caribbean Dollar",
    country: "Saint Vincent and Grenadine",
  },
  {
    currency: "XPF",
    symbol: "₣",
    name: "CFP Franc",
    country: "French Polynesia",
  },
  {
    currency: "XPF",
    symbol: "₣",
    name: "CFP Franc",
    country: "New Caledonia",
  },
  {
    currency: "XPF",
    symbol: "₣",
    name: "CFP Franc",
    country: "Wallis and Futuna",
  },
  {
    currency: "YER",
    symbol: "	﷼",
    name: "Yemeni Rial",
    country: "Yemen",
  },
  {
    currency: "ZAR",
    symbol: "R",
    name: "Rand",
    country: "Lesotho",
  },
  {
    currency: "ZAR",
    symbol: "R",
    name: "Rand",
    country: "Namibia",
  },
  {
    currency: "ZAR",
    symbol: "R",
    name: "Rand",
    country: "South Africa",
  },
  {
    currency: "ZMW",
    symbol: "ZK",
    name: "Zambian Kwacha",
    country: "Zambia",
  },
  {
    currency: "ZWL",
    symbol: "$",
    name: "Zimbabwe Dollar",
    country: "Zimbabwe",
  },
];
