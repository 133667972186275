export const page_size = [
  {
    size: "a0",
  },
  {
    size: "a1",
  },
  {
    size: "a2",
  },
  {
    size: "a3",
  },
  {
    size: "a4",
  },
  {
    size: "a5",
  },
  {
    size: "b0",
  },
  {
    size: "b1",
  },
  {
    size: "b2",
  },
  {
    size: "b3",
  },
  {
    size: "b4",
  },
  {
    size: "b5",
  },
  {
    size: "letter",
  },
  {
    size: "government-letter",
  },
  {
    size: "legal",
  },
  {
    size: "junior-legal",
  },
  {
    size: "junior-legal",
  },
];
